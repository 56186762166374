import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import PlanFormHeader from '../../Components/Benefits/PlanFormHeader';
import AdvancedCustomizationForm, {validateOnSubmit as validateAdvancedCustomizationFormOnSubmit} from '../../Components/Benefits/AdvancedCustomizationForm';
import DetailsActionBar from '../../Components/shared/DetailsActionBar';
import {Form} from '../../Components/shared/FormComponents';
import withPopup from '../withPopup';
import withPlanCopying from '../withPlanCopying';
import usePrevious from '../../hooks/usePrevious';
import {
    requestAncillaryPlanDetailsDeleting,
    requestAncillaryPlanDetailsData,
    requestAncillaryPlanDetailsSetting,
    requestCustomizedAncillaryPlanDetailsDeleting,
    requestCustomizedAncillaryPlanDetailsData,
    requestCustomizedAncillaryPlanDetailsSetting,
    requestAncillaryConditionalVariablesSetting,
    setIsCopyingEnabled,
    requestDefaultConditionalVariables
} from '../../actions/benefits';
import {getProfilePermissions} from '../../selectors/general';
import {getIsCopyingEnabled} from '../../selectors/benefits';
import {compose, getDottedObj, isEmpty, getErrorFieldNames, equal} from '../../utils';
import {getRxPlanCopiedFromMedical} from '../../helpers';
import {FORMS} from '../../constants';

const POPUP_ID = 'ancillaryPlanDetailsPopup';

const withAncillaryPlanDetails = (recommendedFields = [], validate, validateOnSubmit = () => {}) => Component => {
    const WithAncillaryPlanDetails = props => {
        const {
            card,
            title,
            planCategory,
            planId,
            isReadonly,
            isCustomizable,
            isCustomizedMode,
            getCopiedPlanDetails,
            setCustomizedMode,
            onSuccess,
            ...restProps
        } = props;
        const dispatch = useDispatch();
        const [initialValues, setInitialValues] = useState({});
        const prevPlanId = usePrevious(planId);
        const {planPeriodId} = useParams();
        const isCopyingEnabled = useSelector(getIsCopyingEnabled);
        const profilePermissions = useSelector(getProfilePermissions);
        let form;

        useEffect(() => {
            (async () => {
                if (isCopyingEnabled && equal(planId, prevPlanId)) {
                    return;
                }

                const onRequestFormData = isCustomizedMode ? requestCustomizedAncillaryPlanDetailsData : requestAncillaryPlanDetailsData;
                const {ancillaryPlanDetails, isSuccess} = await dispatch(onRequestFormData(planId, planCategory));

                if (isSuccess) {
                    setInitialValues(ancillaryPlanDetails);
                }
            })();
        // FYI: we shouldn't perform this effect once isCopyingEnabled or prevPlanId are changed.
        // It may lead to unexpected behaviour due to specific requirements.
        // That should be changed once we get rid of non-customizable ancillary plans and start getting plan data using the only one endpoint (30.11.2022, Oleh);
        }, [dispatch, planId, planCategory, isCustomizedMode]);

        const onDelete = ({id: planId}) => isCustomizedMode ? dispatch(requestCustomizedAncillaryPlanDetailsDeleting(planId)) : dispatch(requestAncillaryPlanDetailsDeleting(planId));

        const copyPlan = useCallback(async (planDetails, isCopyingAcrossDifferentCompanies) => {
            const {id: cardViewId} = initialValues?.card_view || {};
            const {conditions} = await dispatch(requestDefaultConditionalVariables(planDetails?.category));
            const {customized: isPlanCustomized, blocks: copiedBlocks, ...copiedPlanDetails} = getCopiedPlanDetails(planDetails, isCopyingAcrossDifferentCompanies);

            dispatch(setIsCopyingEnabled(true));
            form.batch(() => {
                Object.entries(getDottedObj(copiedPlanDetails)).forEach(([fieldName, fieldValue]) => form.change(fieldName, fieldValue));
                form.change('blocks', copiedBlocks); // FYI: blocks have a lot of nesting therefore we are changing the "blocks" field in one operation to avoid the performance hit (Vlad, 01.09.21)
                form.change('card_view.conditions', conditions);
                form.change('copied_from_plan_id', planDetails.id);
            });

            dispatch(requestAncillaryConditionalVariablesSetting(planId, {conditions, ...cardViewId && {id: cardViewId}}));
            setCustomizedMode(isPlanCustomized);
        }, [dispatch, form, getCopiedPlanDetails, setCustomizedMode, planId, initialValues]);

        const copyPlanRxFromMedical = useCallback(async medicalPlanDetails => {
            const {values} = form.getState();
            dispatch(setIsCopyingEnabled(true));

            const {blocks: copiedBlocks, ...copiedPlanDetails} = getRxPlanCopiedFromMedical(values, medicalPlanDetails);
            form.batch(() => {
                Object.entries(getDottedObj(copiedPlanDetails)).forEach(([fieldName, fieldValue]) => form.change(fieldName, fieldValue));
                form.change('blocks', copiedBlocks); // FYI: blocks have a lot of nesting therefore we are changing the "blocks" field in one operation to avoid the performance hit (Vlad, 01.09.21)
            });
        }, [dispatch, form, planId]); // FYI: planId is required in dependency list for being sure that form is up-to-date (Oleh, 19.09.23)

        const onSubmit = useCallback(async values => {
            const {meta = {}, blocks, card_view: cardView, completed: isPlanCompleted, ...restValues} = values;
            const isCompleted = values.completed || meta.completed; // FYI: if plan is already completed according to requirements we need to send always true as "completed", even if it's save progress action (Pasha, 18.01.2021)

            const validationErrors = isCustomizedMode
                ? validateAdvancedCustomizationFormOnSubmit(values, {isCustomizedMode})
                : validateOnSubmit(values);

            if (meta.completed && !isEmpty(getErrorFieldNames(validationErrors))) {
                return validationErrors;
            }

            const categoryAndTrackingInfo = {
                card_view: {
                    category_and_tracking_info: {
                        card_procedure_ids: cardView?.category_and_tracking_info?.card_procedure_ids,
                        card_specialty_group_ids: cardView?.category_and_tracking_info?.card_specialty_group_ids,
                        category_id: cardView?.category_and_tracking_info?.category_id,
                        events_tracking: cardView?.category_and_tracking_info?.events_tracking,
                        health_goals_mapping: cardView?.category_and_tracking_info?.health_goals_mapping,
                        is_find_care_eligible: cardView?.category_and_tracking_info?.is_find_care_eligible,
                        redirects_representation: cardView?.category_and_tracking_info?.redirects_representation,
                        roi: cardView?.category_and_tracking_info?.roi,
                        product_alias: cardView?.category_and_tracking_info?.product_alias
                    }
                }
            };

            const isNotCustomizedCardValues = {...restValues, ...categoryAndTrackingInfo};
            const enhancedValues = {...(isCustomizedMode ? values : isNotCustomizedCardValues), completed: isCompleted};
            const updateFn = isCustomizedMode ? requestCustomizedAncillaryPlanDetailsSetting : requestAncillaryPlanDetailsSetting;
            const {ancillaryPlanDetails, isSuccess, submissionErrors} = await dispatch(updateFn(planId, enhancedValues));

            if (!isSuccess) {
                return submissionErrors;
            }

            setInitialValues(ancillaryPlanDetails);
        }, [dispatch, isCustomizedMode, planId]);

        const DetailsForm = isCustomizedMode ? AdvancedCustomizationForm : Component;

        return (
            <Form key={planId} name={FORMS.ancillaryPlanDetails} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit, form: formInstance}) => {
                    form = formInstance;

                    const headerProps = {
                        title,
                        planId,
                        planCategory,
                        isReadonly,
                        isCustomizable,
                        onSubmit: handleSubmit,
                        copyPlan,
                        copyPlanRxFromMedical,
                        isCustomizedMode,
                        setCustomizedMode,
                        requestConditionalVariablesSetting: (planId, conditions) => dispatch(requestAncillaryConditionalVariablesSetting(planId, conditions)),
                        ...restProps
                    };

                    const componentProps = {form, planId, isReadonly, card, ...restProps};
                    const actionBarProps = {
                        isReadonly,
                        recommendedFields,
                        onSuccess,
                        onSubmit: handleSubmit,
                        onDelete: profilePermissions.ancillary_plan_delete[planPeriodId] ? onDelete : undefined
                    };

                    return (
                        <form noValidate data-testid='ancillary-plan-details-form'>
                            <PlanFormHeader {...headerProps} isAvailabilityConditionShowed/>
                            <DetailsForm {...componentProps}/>
                            <DetailsActionBar {...actionBarProps}/>
                        </form>
                    );
                }}
            </Form>
        );
    };

    WithAncillaryPlanDetails.propTypes = {
        planId: PropTypes.number,
        planCategory: PropTypes.string,
        card: PropTypes.string,
        title: PropTypes.string,
        isReadonly: PropTypes.bool,
        isCustomizedMode: PropTypes.bool,
        isCustomizable: PropTypes.bool,
        setCustomizedMode: PropTypes.func,
        onSuccess: PropTypes.func,
        getCopiedPlanDetails: PropTypes.func,
        openPopup: PropTypes.func,
        closePopup: PropTypes.func
    };

    return WithAncillaryPlanDetails;
};

export {withAncillaryPlanDetails as testableWithAncillaryPlanDetails};
export default ({recommendedFields, validate, validateOnSubmit}) => compose(
    withPopup(POPUP_ID),
    withPlanCopying,
    withAncillaryPlanDetails(recommendedFields, validate, validateOnSubmit)
);
