import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useLocation, useHistory} from 'react-router-dom';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Tabs from '@frontend/ui-kit/Components/Tabs';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import GeneralInformation from './GeneralInformation';
import GeneralMemberStatistics from './GeneralMemberStatistics';
import BenefitStatistics from './BenefitStatistics';
import FileErrors from './FileErrors';
import Thresholds from './Thresholds';
import CongratulationPopup from '../../shared/CongratulationPopup';
import withPopup from '../../../HOC/withPopup';
import {redirectTo} from '../../../actions/general';
import {
    requestEligibilityImportSessionErrors,
    requestEligibilityImportSessionStats,
    requestEligibilityImportSessionUpdating,
    requestImportsJsonrpc,
    requestMassMembersChanges
} from '../../../actions/adminPortal';
import {showToast} from '../../../actions/shared';
import {getProfileInfo} from '../../../selectors/general';
import {
    equal,
    generateUniqueId,
    getEqual,
    getItemKeyValue,
    isEmpty,
    omit,
    parseQuery,
    stringifyQueryParams
} from '../../../utils';
import {ROUTES, ELIGIBILITY_IMPORT_STATUSES, AUTOIMPORT_CREATED_BY, JSON_RPC_RESULTS} from '../../../constants';
import {getImportThresholdsMassMembersText, getIsImportSessionThresholds} from '../../../helpers';
import doctorIcon from '../../../static/images/doctor.svg';
import './index.scss';

const POPUP_ID = 'eligibilityImportDetailsPopup';

const IMPORT_DETAILS_TABS = [
    {key: 'General Information', value: 'import_info', component: GeneralInformation},
    {key: 'General Member Statistics', value: 'general_statistics', component: GeneralMemberStatistics},
    {key: 'Benefit Statistics', value: 'benefits_statistics', component: BenefitStatistics},
    {key: 'Thresholds', value: 'thresholds', component: Thresholds},
    {key: 'File Errors', value: 'file_errors', component: FileErrors}
];

const EligibilityImportDetails = ({openPopup, closePopup}) => {
    const {email: userEmail} = useSelector(getProfileInfo);
    const [importStats, setImportStats] = useState({});
    const [isBlockersErrors, setIsBlockersErrors] = useState(false);
    const {id: importId, partner, group_alias: groupAlias} = useParams();
    const {hash, search} = useLocation();
    const {push} = useHistory();
    const [activeTab, setActiveTab] = useState(0);
    const [massMembersChangedText, setMassMembersChangedText] = useState('');
    const dispatch = useDispatch();
    const tabs = useMemo(() => {
        const errorsCount = importStats?.import_info?.additional_data?.errors_count || 0;

        const getThresholdsTitle = key => {
            const {migrated_members: migratedMembers, members_pending_termination: terminationMembers} = importStats?.import_info?.thresholds || {};
            const thresholds = [migratedMembers?.data?.length, terminationMembers?.data?.length].filter(Boolean);
            return thresholds.length ? `${key} (${thresholds.length})` : key;
        };

        return IMPORT_DETAILS_TABS
            .map(({key, value, ...details}) => {
                const tabNameWithCount = {
                    file_errors: `${key} (${errorsCount})`,
                    thresholds: getThresholdsTitle(key)
                }[value];

                return ({...details, value, key: tabNameWithCount || key});
            })
            .map(getItemKeyValue('key'));
    }, [importStats]);
    const {component} = IMPORT_DETAILS_TABS[activeTab];
    const Component = component || {};

    const isPendingConfirmationStatus = equal(importStats?.import_info?.status, ELIGIBILITY_IMPORT_STATUSES.pending_confirmation);
    const [isAutoImportFlow, setIsAutoImportFlow] = useState(false);
    const isAutoImportThresholds = isAutoImportFlow && getIsImportSessionThresholds(importStats?.import_info);

    useEffect(() => {
        const tabsIndex = IMPORT_DETAILS_TABS.findIndex(getEqual(hash.slice(1), 'value'));
        const activeTabIndex = equal(tabsIndex, -1) ? 0 : tabsIndex;

        setActiveTab(activeTabIndex);
    }, [hash]);

    const onChangeTab = activeTabIndex => push(`${search}#${IMPORT_DETAILS_TABS[activeTabIndex]?.value}`);

    const onGoToImports = () => dispatch(redirectTo(ROUTES.eligibilityImports));

    const onRequestImportSessionStats = useCallback(async () => {
        const [
            {data, isSuccess},
            {data: errors, isSuccess: isErrorsSuccess},
            {data: massMembersChanges, isSuccess: isMassMembersChangesSuccess}
        ] = await Promise.all([
            dispatch(requestEligibilityImportSessionStats(importId)),
            dispatch(requestEligibilityImportSessionErrors(importId)),
            dispatch(requestMassMembersChanges(importId))
        ]);

        if (!isSuccess || !isErrorsSuccess || !isMassMembersChangesSuccess) {
            onGoToImports();

            return false;
        }

        setIsBlockersErrors(!!errors?.blockers?.length);
        setImportStats(data);
        setMassMembersChangedText(getImportThresholdsMassMembersText(massMembersChanges));
        const isAutoImport = equal(data?.import_info?.additional_data?.created_by, AUTOIMPORT_CREATED_BY) || !isEmpty(data?.import_info?.references);
        setIsAutoImportFlow(isAutoImport);
    }, [importId, userEmail]);

    useEffect(() => {
        onRequestImportSessionStats();
    }, []);

    const redirectToAutoimportFileUpload = () => {
        const {import_info: importInfo} = importStats;
        const enhancedSearch = stringifyQueryParams({
            ...parseQuery(search),
            autoImportId: importId,
            ...partner && {group_name: importInfo?.additional_data?.company_name}
        });

        const route = partner
            ? `${ROUTES.importsTpa}/${partner}/${importInfo?.company_alias}/group_config?${enhancedSearch}#upload_file`
            : `${ROUTES.importsIndividual}/${groupAlias}/group_config?${enhancedSearch}#upload_file`;
        dispatch(redirectTo(route));
    };

    const redirectToFileUpload = () => {
        const route = partner
            ? `${ROUTES.importsTpa}/${partner}/tpa_config${search}#upload_file`
            : `${ROUTES.importsIndividual}/${groupAlias}/group_config${search}#upload_file`;
        dispatch(redirectTo(route));
    };

    const onOpenReUploadPopup = () => {
        const updateSessionStatus = async () => {
            const {isSuccess} = await dispatch(requestEligibilityImportSessionUpdating(importId, {status: ELIGIBILITY_IMPORT_STATUSES.cancelled}));

            if (!isSuccess) {
                return false;
            }
        };

        const onReUploadFile = async () => {
            await updateSessionStatus();
            redirectToFileUpload();
            closePopup();
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='cancel-popup-button' type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button data-testid='re-upload-popup-button' type={BUTTON_TYPES.primary} onClick={onReUploadFile} className='ml-8'>
                    Yes, Continue to Upload Eligibility File
                </Button>
            </React.Fragment>
        );
        const popupContent = <Text>You are about to re-upload the eligibility file according to the most up-to-date import config. Any progress completed will not be saved. Are you sure you want to continue?</Text>;

        const popupProps = {title: 'Re-upload File Confirmation', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const openCongratulationPopup = () => {
        const onStayOnPage = () => {
            onRequestImportSessionStats();
            closePopup();
        };

        const actionBar = <Button data-testid='stay' type={BUTTON_TYPES.primary} onClick={onStayOnPage}>Okay</Button>;
        const icon = <img src={doctorIcon} alt='doctor'/>;
        const children = (
            <CongratulationPopup actionBar={actionBar} icon={icon}>
                <Text>Data has been submitted to the database.</Text>
            </CongratulationPopup>
        );

        return openPopup({type: POPUP_TYPES.fullscreen, children});
    };

    const onSubmitToDatabase = () => {
        const onSubmit = async () => {
            const jsonrpcObj = {jsonrpc: '2.0', method: 'continue_import', id: generateUniqueId(), params: {session_id: Number(importId)}};
            const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

            if (!equal(jsonrpc?.result, JSON_RPC_RESULTS.success)) {
                return false;
            }

            if (isAutoImportFlow) {
                dispatch(showToast({content: 'Threshold confirmed and import submitted to database!'}));
                dispatch(redirectTo(ROUTES.eligibilityImports));

                return false;
            }

            openCongratulationPopup();
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='cancel-popup-button' type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button data-testid='submit-to-database-popup-button' type={BUTTON_TYPES.primary} onClick={onSubmit} className='ml-8'>
                    Yes, Submit to Database
                </Button>
            </React.Fragment>
        );
        const popupContent = isBlockersErrors
            ? <Text>Are you sure you want to submit this file to the database? <br/><br/>This validation report contains errors that are BLOCKERS. Once you submit this file to database, you will no longer be able make updates to this file’s blocker errors, as well as the migration or termination approvals. Please ensure these blockers are resolved and approvals are finalized. If updates are needed to unblock these errors, you will need to re-upload an adjusted file.</Text>
            : <Text>Are you sure you want to submit this file to the database? <br/><br/>Once you submit to database, you will no longer be able make updates to file errors and the migration or termination approvals. Please ensure these approvals are finalized. If updates are needed, you may always re-upload the file.</Text>;

        const popupProps = {title: 'Confirmation Required', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const onClientConfirm = () => {
        const onConfirm = () => {
            closePopup();
            onSubmitToDatabase();
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='cancel-popup-button' type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button data-testid='client-confirmed-popup-button' type={BUTTON_TYPES.primary} onClick={onConfirm} className='import-details__popup-button'>
                    {isAutoImportFlow ? 'Yes, Confirm Submit to Database' : 'Yes, Client Confirmed'}
                </Button>
            </React.Fragment>
        );
        const popupContent = (
            <React.Fragment>
                <Text className='mb-10'>Are you sure you want to submit this file to the database?</Text>
                <Text><Text type={TEXT_TYPES.bodyBold} isInline>A {massMembersChangedText} threshold(s) was detected.</Text> Once you submit this file to database, you will no longer be able to make changes. Please ensure client confirmation was acquired. If updates are needed to unblock these errors, you will need to re-upload an adjusted file.</Text>
            </React.Fragment>
        );

        const popupProps = {title: 'Confirmation Required on Thresholds', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    return (
        <div className='import-details'>
            {!isAutoImportFlow && !isEmpty(massMembersChangedText) && <Alert className='mb-20' type={ALERT_TYPES.danger} title={`${massMembersChangedText} Threshold(s) Detected`} description='Please reach out to the client to confirm prior to submitting this validation file to the database.'/>}

            {!!isAutoImportThresholds && <Alert className='mb-20' type={ALERT_TYPES.danger} description={`The automated import has ${massMembersChangedText} threshold${importStats?.import_info?.has_blockers ? ' and blockers' : ''}. The file was not imported. Please review and take action accordingly.`}/>}

            <Row middle='xs'>
                <Column sm>
                    <Heading type={HEADING_TYPES['3']}>{importStats?.import_info?.additional_data?.company_name}</Heading>
                </Column>

                {isPendingConfirmationStatus && (
                    <Column>
                        <Button data-testid='re-upload-file-button' onClick={isAutoImportFlow ? redirectToAutoimportFileUpload : onOpenReUploadPopup} type={BUTTON_TYPES.secondary} className='mr-8'>
                            {isAutoImportFlow ? 'Manual File Upload' : 'Re-upload File'}
                        </Button>
                        <Button data-testid='submit-button' onClick={!isEmpty(massMembersChangedText) ? onClientConfirm : onSubmitToDatabase}>
                            Submit {isAutoImportThresholds && 'Threshold Members'} to Database
                        </Button>
                    </Column>
                )}
            </Row>

            <ContentSection className='mt-20'>
                <Tabs className='mt-10 mb-10' tabs={tabs} onChange={onChangeTab} activeTab={activeTab}/>

                {!isEmpty(importStats) && <Component {...importStats} isAutoImport={isAutoImportFlow}/>}
            </ContentSection>
        </div>
    );
};

EligibilityImportDetails.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {EligibilityImportDetails as TestableEligibilityImportDetails};
export default withPopup(POPUP_ID)(EligibilityImportDetails);
